export const TIMEFRAME_INTERVAL = {
  hourly: '30m',
  daily: '1h',
  weekly: '1d',
};

export const DEFAULT_TIMEFRAME_MULTIPLIER = 4.5;
export const TIMEFRAME_MULTIPLIER = {
  hourly: 0.5,
  daily: 2,
};

export const TREND_POSITION = {
  Positive: 'Long',
  Negative: ' Short',
  Sideways: 'No Directional Trade',
};

export const ONE_MINUTE = 'minute';
export const FIVE_MINUTE = 'fiveminute';
export const FIFTEEN_MINUTE = 'fifteenminute';
export const THIRTY_MINUTE = 'thirtyminute';
export const FOUR_HOUR = 'fourhour';
export const HOURLY = 'hourly';
export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';

export const TIME_FRAMES_VALUES_LIST = [
  ONE_MINUTE,
  FIVE_MINUTE,
  FIFTEEN_MINUTE,
  THIRTY_MINUTE,
  FOUR_HOUR,
  HOURLY,
  DAILY,
  WEEKLY,
  MONTHLY,
];

export const TIMEFRAME_INTERVAL_VALUES = {
  [ONE_MINUTE]: '1m',
  [FIVE_MINUTE]: '5m',
  [FIFTEEN_MINUTE]: '15m',
  [THIRTY_MINUTE]: '30m',
  [FOUR_HOUR]: '4h',
  [HOURLY]: '1h',
  [DAILY]: '1D',
  [WEEKLY]: '1W',
  [MONTHLY]: '1M',
};

export const TIMEFRAME_INTERVAL_TITLES = {
  [ONE_MINUTE]: '1 Minute',
  [FIVE_MINUTE]: '5 Minute',
  [FIFTEEN_MINUTE]: '15 Minute',
  [THIRTY_MINUTE]: '30 Minute',
  [FOUR_HOUR]: '4 Hour',
  [HOURLY]: 'Hourly',
  [DAILY]: 'Daily',
  [WEEKLY]: 'Weekly',
  [MONTHLY]: 'Monthly',
};

export const UNTOUCH_INTERVAL_VALUES = {
  '1mo': 'M',
  '1w': 'W',
  '1d': 'D',
  '4h': '4H',
  '1h': '1H',
  '30m': '30m',
  '15m': '15m',
  '5m': '5m',
};

export const UNTOUCH_INTERVAL_COLORS = {
  '1mo': '#E86100',
  '1w': '#ffd32c',
  '1d': '#274ab3',
  '4h': 'purple',
  '1h': 'turquoise',
  '30m': 'pink',
  '15m': '#82c8e5',
  '5m': '#adebb3',
};

export const MULTITIMEFRAME_INTERVALS = {
  scalp: [
    {
      title: '1 min',
      value: ONE_MINUTE,
    },
    {
      title: '5 min',
      value: FIVE_MINUTE,
    },
    {
      title: '15 min',
      value: FIFTEEN_MINUTE,
    },
  ],
  activeIntraday: [
    {
      title: '5 min',
      value: FIVE_MINUTE,
    },
    {
      title: '15 min',
      value: FIFTEEN_MINUTE,
    },
    {
      title: '1 hr',
      value: HOURLY,
    },
  ],
  intraday: [
    {
      title: '5 min',
      value: FIVE_MINUTE,
    },
    {
      title: '30 min',
      value: THIRTY_MINUTE,
    },
    {
      title: '4 hr',
      value: FOUR_HOUR,
    },
  ],
  intraSwing: [
    {
      title: '15 min',
      value: FIFTEEN_MINUTE,
    },
    {
      title: '1 hr',
      value: HOURLY,
    },
    {
      title: '4 hr',
      value: FOUR_HOUR,
    },
  ],
  activeSwing: [
    {
      title: '30 min',
      value: THIRTY_MINUTE,
    },
    {
      title: '4 hr',
      value: FOUR_HOUR,
    },
    {
      title: 'Daily',
      value: DAILY,
    },
  ],
  swing: [
    {
      title: '1 hr',
      value: HOURLY,
    },
    {
      title: 'Daily',
      value: DAILY,
    },
    {
      title: 'Weekly',
      value: WEEKLY,
    },
  ],
  investor: [
    {
      title: 'Daily',
      value: DAILY,
    },
    {
      title: 'Weekly',
      value: WEEKLY,
    },
    {
      title: 'Monthly',
      value: MONTHLY,
    },
  ],
};
