import {
  CREATE_NEW_PASSWORD_ACTION,
  FORGOT_PASSWORD_ACTION,
  LOGIN_ACTION,
  LOGOUT_ACTION,
  SET_CREATING_NEW_PASSWORD_ACTION,
  SAVE_USER_DETAILS_ACTION,
  TOGGLE_SUCCESS_MODAL,
  TOGGLE_LOCK_MODAL,
  GET_USER_PERMISSION_ACTION,
} from '../constants/auth.constant';
import { TRAINING_MODAL, QUIZ_ENTRY_MODAL, ADX_TICKER } from '../../constants/modal';
import { LOGOUT } from '../constants/technical_analysis.constant';

// export const loginAction = () => ({ type: LOGIN_ACTION });
export const logoutAction =
  (redirect = undefined) =>
  (dispatch) => {
    localStorage.removeItem(TRAINING_MODAL);
    localStorage.removeItem(QUIZ_ENTRY_MODAL);
    localStorage.removeItem(ADX_TICKER);
    dispatch({ type: LOGOUT });

    dispatch({ type: LOGOUT_ACTION, redirect });
  };

export const forgotPasswordAction = (params) => ({ type: FORGOT_PASSWORD_ACTION, params });

export const createNewPasswordAction = (params) => ({ type: CREATE_NEW_PASSWORD_ACTION, params });

export const setCreatingNewPasswordAction = (value) => ({
  type: SET_CREATING_NEW_PASSWORD_ACTION,
  value,
});
export const getPermission = () => ({ type: GET_USER_PERMISSION_ACTION });
export const saveUserDetails = (id, params, enqueueSnackbar) => ({
  type: SAVE_USER_DETAILS_ACTION,
  id,
  params,
  enqueueSnackbar,
});
export const toggleSuccessModal = (value) => ({ type: TOGGLE_SUCCESS_MODAL, value });
export const toggleLockModal = (value) => ({ type: TOGGLE_LOCK_MODAL, value });
