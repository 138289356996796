export const RETRIEVE_ADX_STOCKS_ACTION = 'technical_analysis/RETRIEVE_ADX_STOCKS_ACTION';
export const RETRIEVE_ADX_STOCKS_OK = 'technical_analysis/RETRIEVE_ADX_STOCKS_OK';
export const RETRIEVE_ADX_STOCKS_ERROR = 'technical_analysis/RETRIEVE_ADX_STOCKS_ERROR';
export const RETRIEVE_ADX_STOCKS_END = 'technical_analysis/RETRIEVE_ADX_STOCKS_END';
export const RETRIEVE_ADX_VIX_STOCKS_ACTION = 'technical_analysis/RETRIEVE_ADX_VIX_STOCKS_ACTION';
export const RETRIEVE_ADX_VIX_STOCKS_OK = 'technical_analysis/RETRIEVE_ADX_VIX_STOCKS_OK';
export const RETRIEVE_ADX_VIX_STOCKS_ERROR = 'technical_analysis/RETRIEVE_ADX_VIX_STOCKS_ERROR';
export const RETRIEVE_ADX_VIX_STOCKS_END = 'technical_analysis/RETRIEVE_ADX_VIX_STOCKS_END';
export const RETRIEVE_ADX_DAILY = 'technical_analysis/RETRIEVE_ADX_DAILY';
export const RETRIEVE_ADX_DAILY_OK = 'technical_analysis/RETRIEVE_ADX_DAILY_OK';

export const RETRIEVE_TIERED_CHART_DATA_ACTION =
  'technical_analysis/RETRIEVE_TIERED_CHART_DATA_ACTION';
export const RETRIEVE_TIERED_CHART_DATA_OK = 'technical_analysis/RETRIEVE_TIERED_CHART_DATA_OK';
export const RETRIEVE_TIERED_CHART_DATA_ERROR =
  'technical_analysis/RETRIEVE_TIERED_CHART_DATA_ERROR';
export const RETRIEVE_TIERED_CHART_DATA_END = 'technical_analysis/RETRIEVE_TIERED_CHART_DATA_END';

export const EDIT_ADX_PARAMS = 'technical_analysis/EDIT_ADX_PARAMS';

export const RETRIEVE_CANDLESTICK_ANALYSIS_ACTION =
  'technical_analysis/RETRIEVE_CANDLESTICK_ANALYSIS_ACTION';
export const RETRIEVE_CANDLESTICK_ANALYSIS_OK =
  'technical_analysis/RETRIEVE_CANDLESTICK_ANALYSIS_OK';
export const RETRIEVE_CANDLESTICK_ANALYSIS_ERROR =
  'technical_analysis/RETRIEVE_CANDLESTICK_ANALYSIS_ERROR';
export const RETRIEVE_CANDLESTICK_ANALYSIS_END =
  'technical_analysis/RETRIEVE_CANDLESTICK_ANALYSIS_END';

export const RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_FREQUENCY_ACTION =
  'technical_analysis/RETRIEVE_ASSETS_SNR_STOCK_FREQUENCY_ACTION';
export const RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_OK =
  'technical_analysis/RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_OK';
export const RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_ERROR =
  'technical_analysis/RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_ERROR';
export const RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_END =
  'technical_analysis/RETRIEVE_ASSETS_SNR_FREQUENCY_STOCK_END';

export const RETRIEVE_TRADE_SETUP_ACTION = 'technical_analysis/RETRIEVE_TRADE_SETUP_ACTION';
export const RETRIEVE_TRADE_SETUP_OK = 'technical_analysis/RETRIEVE_TRADE_SETUP_OK';
export const RETRIEVE_TRADE_SETUP_ERROR = 'technical_analysis/RETRIEVE_TRADE_SETUP_ERROR';
export const RETRIEVE_TRADE_SETUP_END = 'technical_analysis/RETRIEVE_TRADE_SETUP_END';

export const RETRIEVE_INDEX_PERFORMANCE_ACTION =
  'technical_analysis/RETRIEVE_INDEX_PERFORMANCE_ACTION';
export const RETRIEVE_INDEX_PERFORMANCE_OK = 'technical_analysis/RETRIEVE_INDEX_PERFORMANCE_OK';
export const RETRIEVE_INDEX_PERFORMANCE_ERROR =
  'technical_analysis/RETRIEVE_INDEX_PERFORMANCE_ERROR';
export const RETRIEVE_INDEX_PERFORMANCE_END = 'technical_analysis/RETRIEVE_INDEX_PERFORMANCE_END';

export const RETRIEVE_COMPARATIVE_PERFORMANCE_ACTION =
  'technical_analysis/RETRIEVE_COMPARATIVE_PERFORMANCE_ACTION';
export const RETRIEVE_COMPARATIVE_PERFORMANCE_OK =
  'technical_analysis/RETRIEVE_COMPARATIVE_PERFORMANCE_OK';
export const RETRIEVE_COMPARATIVE_PERFORMANCE_ERROR =
  'technical_analysis/RETRIEVE_COMPARATIVE_PERFORMANCE_ERROR';
export const RETRIEVE_COMPARATIVE_PERFORMANCE_END =
  'technical_analysis/RETRIEVE_COMPARATIVE_PERFORMANCE_END';

export const RETRIEVE_UNTOUCHED_SNR_ACTION = 'technical_analysis/RETRIEVE_UNTOUCHED_SNR_ACTION';
export const RETRIEVE_UNTOUCHED_SNR_OK = 'technical_analysis/RETRIEVE_UNTOUCHED_SNR_OK';
export const RETRIEVE_UNTOUCHED_SNR_ERROR = 'technical_analysis/RETRIEVE_UNTOUCHED_SNR_ERROR';
export const RETRIEVE_UNTOUCHED_SNR_END = 'technical_analysis/RETRIEVE_UNTOUCHED_SNR_END';

export const RETRIEVE_VOLATILITY_GAUGE_ACTION =
  'technical_analysis/RETRIEVE_VOLATILITY_GAUGE_ACTION';
export const RETRIEVE_VOLATILITY_GAUGE_OK = 'technical_analysis/RETRIEVE_VOLATILITY_GAUGE_OK';
export const RETRIEVE_VOLATILITY_GAUGE_ERROR = 'technical_analysis/RETRIEVE_VOLATILITY_GAUGE_ERROR';
export const RETRIEVE_VOLATILITY_GAUGE_END = 'technical_analysis/RETRIEVE_VOLATILITY_GAUGE_END';

export const RETRIEVE_VOLATILITY_ATR_ACTION = 'technical_analysis/RETRIEVE_VOLATILITY_ATR_ACTION';
export const RETRIEVE_VOLATILITY_ATR_OK = 'technical_analysis/RETRIEVE_VOLATILITY_ATR_OK';
export const RETRIEVE_VOLATILITY_ATR_ERROR = 'technical_analysis/RETRIEVE_VOLATILITY_ATR_ERROR';
export const RETRIEVE_VOLATILITY_ATR_END = 'technical_analysis/RETRIEVE_VOLATILITY_ATR_END';

export const SELECT_PERFORMANCE_DATA_ACTION = 'technical_analysis/SELECT_PERFORMANCE_DATA_ACTION';

export const TOGGLE_MAX_AMOUNT_MODAL = 'technical_analysis/TOGGLE_MAX_AMOUNT_MODAL';

//ETF COMPARATIVE PERFORMANCE DATA
export const RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ACTION =
  'technical_analysis/RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ACTION';
export const RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_OK =
  'technical_analysis/RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_OK';
export const RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ERROR =
  'technical_analysis/RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_ERROR';
export const RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_END =
  'technical_analysis/RETRIEVE_ETF_COMPARATIVE_PERFORMANCE_END';
export const SET_ETF_COMPARATIVE_PERFORMANCE_PARAMS_ACTION =
  'technical_analysis/SET_ETF_COMPARATIVE_PERFORMANCE_PARAMS_ACTION';

//HOLDING COMPARATIVE PERFORMANCE DATA
export const RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ACTION =
  'technical_analysis/RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ACTION';
export const RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_OK =
  'technical_analysis/RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_OK';
export const RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ERROR =
  'technical_analysis/RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_ERROR';
export const RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_END =
  'technical_analysis/RETRIEVE_HOLDING_COMPARATIVE_PERFORMANCE_END';
export const SET_HOLDING_COMPARATIVE_PERFORMANCE_PARAMS_ACTION =
  'technical_analysis/SET_HOLDING_COMPARATIVE_PERFORMANCE_PARAMS_ACTION';

//FUND SCREENING DATA
export const RETRIEVE_FUND_SCREENING_ACTION = 'technical_analysis/RETRIEVE_FUND_SCREENING_ACTION';
export const RETRIEVE_FUND_SCREENING_OK = 'technical_analysis/RETRIEVE_FUND_SCREENING_OK';
export const RETRIEVE_FUND_SCREENING_ERROR = 'technical_analysis/RETRIEVE_FUND_SCREENING_ERROR';
export const RETRIEVE_FUND_SCREENING_END = 'technical_analysis/RETRIEVE_FUND_SCREENING_END';
export const SET_FUND_SCREENING_PARAMS_ACTION =
  'technical_analysis/SET_FUND_SCREENING_PARAMS_ACTION';

//ADX MI STOCKS
export const RETRIEVE_ADX_MI_STOCKS_ACTION = 'technical_analysis/RETRIEVE_ADX_MI_STOCKS_ACTION';
export const RETRIEVE_ADX_MI_STOCKS_OK = 'technical_analysis/RETRIEVE_ADX_MI_STOCKS_OK';
export const RETRIEVE_ADX_MI_STOCKS_ERROR = 'technical_analysis/RETRIEVE_ADX_MI_STOCKS_ERROR';
export const RETRIEVE_ADX_MI_STOCKS_END = 'technical_analysis/RETRIEVE_ADX_MI_STOCKS_END';

//TRADE BUILDER SETUP | UNTOUCHED_HIGH_LOW | SNR
export const RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ACTION =
  'technical_analysis/RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ACTION';
export const RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_OK =
  'technical_analysis/RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_OK';
export const RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ERROR =
  'technical_analysis/RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_ERROR';
export const RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_END =
  'technical_analysis/RETRIEVE_TRADE_SETUP_UNTOUCHED_SNR_END';

//TRADE BUILDER SETUP
export const RETRIEVE_TRADE_BUILDER_SETUP_ACTION =
  'technical_analysis/RETRIEVE_TRADE_BUILDER_SETUP_ACTION';
export const RETRIEVE_TRADE_BUILDER_SETUP_OK = 'technical_analysis/RETRIEVE_TRADE_BUILDER_SETUP_OK';
export const RETRIEVE_TRADE_BUILDER_SETUP_ERROR =
  'technical_analysis/RETRIEVE_TRADE_BUILDER_SETUP_ERROR';
export const RETRIEVE_TRADE_BUILDER_SETUP_END =
  'technical_analysis/RETRIEVE_TRADE_BUILDER_SETUP_END';

export const SET_CANDLESTICK_ADJUSTMENTS = 'technical_analysis/SET_CANDLESTICK_ADJUSTMENTS';

export const SET_TRADE_BUILDER_SETUP_MAX_LOSS_ACTION =
  'technical_analysis/SET_TRADE_BUILDER_SETUP_MAX_LOSS_ACTION';

export const SET_SEARCH_TICKER = 'technical_analysis/SET_SEARCH_TICKER';

export const LOGOUT = 'technical_analysis/LOGOUT';
