import React, { useState } from 'react';
import cn from 'classnames';
import styles from './Profile.module.sass';
import { NavLink, withRouter, useLocation } from 'react-router-dom';
import Icon from '../Icon';
import { getUserEmail } from '../../helpers/auth';
import { isTraidifyEmail } from '../../helpers/utils';
//import Breadcrumbs from "../Breadcrumbs";

const Proflie = ({ children }) => {
  const userEmail = getUserEmail();
  const isTraidifyUser = isTraidifyEmail(userEmail);
  const navigation = [
    {
      title: 'Profile',
      icon: 'user',
      url: '/app/profile',
    },
    {
      title: 'Trades',
      icon: 'candlesticks',
      url: '/app/trades',
    },
    {
      title: 'Payments',
      icon: 'share',
      url: '/app/payments',
    },
    {
      title: 'Sessions & login history',
      icon: 'laptop',
      url: '/app/sessions-and-login-history',
    },
    {
      title: '2FA',
      icon: 'barcode',
      url: '/2fa',
    },
    {
      title: 'Change password',
      icon: 'lock',
      url: '/app/change-password',
    },
  ];

  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);

  const activeLink = navigation.find((x) => pathname.includes(x.url));

  return (
    <div className={styles.profile}>
      <div className={styles.body}>
        <div className={cn('container', styles.container)}>
          {!!isTraidifyUser && (
            <div className={styles.sidebar}>
              <div className={cn(styles.dropdown, { [styles.active]: visible })}>
                <div className={styles.top} onClick={() => setVisible(!visible)}>
                  <Icon name={activeLink.icon} size="24" />
                  {activeLink.title}
                </div>
                <div className={styles.group}>
                  <div className={styles.menu}>
                    {navigation.map((item, index) => (
                      <NavLink
                        className={cn(styles.link, {
                          [styles.separator]: item.separator,
                        })}
                        activeClassName={styles.active}
                        key={index}
                        to={item.url}
                        exact
                        onClick={() => setVisible(false)}
                      >
                        <Icon name={item.icon} size="24" />
                        {item.title}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={styles.wrapper}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Proflie);
