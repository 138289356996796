import { store } from '..';

export const getAuthToken = () => {
  const app = store.getState().auth.toJS();

  return app.token;
};

export const getUserEmail = () => {
  const app = store.getState().auth.toJS();

  return app?.user_data?.email;
};

export const isLoggedIn = () => !!getAuthToken();
