import cn from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Switch from '../../../../components/Switch';
import { THEME_DARK_MODE } from '../../../../constants';
import { toggleThemeAction } from '../../../../redux/actions/app.action';
import { logoutAction } from '../../../../redux/actions/auth.action';
import styles from './User.module.sass';

import { Icon, Typography, useTheme } from '@mui/material';
import { ReactComponent as UserProfileSVG } from '../../../../assets//icons/header/user_profile.svg';
import { getUserEmail } from '../../../../helpers/auth';
import { isTraidifyEmail } from '../../../../helpers/utils';

// Create a styled dropdown with the portal
const DropdownPortal = ({ children, position, isVisible, onClose, theme }) => {
  if (!isVisible) return null;

  // Create the dropdown portal outside any potential stacking contexts
  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        top: position.top,
        right: position.right,
        width: '256px',
        padding: '4px 16px',
        borderRadius: '12px',
        boxShadow: '0px 16px 64px -24px rgba(31, 47, 70, 0.15)',
        background: theme === THEME_DARK_MODE ? '#23262F' : 'white',
        zIndex: 999999,
        overflow: 'visible',
        display: 'block',
        opacity: 1,
        visibility: 'visible',
      }}
    >
      {/* Arrow element */}
      <div
        style={{
          position: 'absolute',
          right: '10px',
          bottom: '100%',
          width: '24px',
          height: '12px',
          backgroundImage:
            theme === THEME_DARK_MODE
              ? "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='9' fill='none' viewBox='0 0 24 9'%3E%3Cpath d='M6.343 2.657L0 9h24l-6.343-6.343a8 8 0 0 0-11.314 0z' fill='%2323262F'/%3E%3C/svg%3E\")"
              : "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='9' fill='none' viewBox='0 0 24 9'%3E%3Cpath d='M6.343 2.657L0 9h24l-6.343-6.343a8 8 0 0 0-11.314 0z' fill='%23fcfcfd'/%3E%3C/svg%3E\")",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 100%',
          backgroundSize: '100% auto',
        }}
      />
      <OutsideClickHandler onOutsideClick={onClose}>{children}</OutsideClickHandler>
    </div>,
    document.body // Render directly to body to avoid stacking context issues
  );
};

const User = ({ className }) => {
  const { user_details } = useSelector((store) => {
    const login_store = store.login;

    if (login_store) return login_store.toJS();

    return {};
  });
  const dispatch = useDispatch();
  const themeObj = useTheme();
  const [visible, setVisible] = useState(false);
  const theme = useSelector((store) => store.app.toJS().theme);
  const userEmail = getUserEmail();
  const isTraidifyUser = isTraidifyEmail(userEmail);
  const buttonRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, right: 0 });

  // Update position when dropdown becomes visible
  useEffect(() => {
    if (visible && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();

      // Calculate position relative to the button
      setPosition({
        top: rect.bottom + window.scrollY + 12, // Reduce the gap to 12px (was 20px)
        right: window.innerWidth - rect.right,
      });
    }
  }, [visible]);

  // Items for dropdown menu
  const items = [
    {
      title: 'Profile',
      icon: 'user',
      content:
        user_details.first_name || user_details.last_name
          ? `${user_details.first_name} ${user_details.last_name}`
          : 'User has no Name',
      url: '/app/profile',
    },
    {
      title: 'Trades',
      icon: 'candlesticks',
      content: 'View trades history',
      url: '/app/trades',
    },
    {
      title: 'Payments',
      icon: 'lock',
      content: 'Setup 2FA for more security',
      url: '/app/payments',
    },
    {
      title: 'Settings',
      icon: 'cog',
      content: 'View additional settings',
      url: '/api-keys',
    },
    {
      title: 'Dark mode',
      icon: 'theme-dark',
      content: 'Switch dark/light mode',
    },
    {
      title: 'Log out',
      icon: 'exit',
      handleClick: () => dispatch(logoutAction()),
    },
  ];

  const notTraidifyUserItems = [
    {
      title: 'Profile',
      icon: 'user',
      content:
        user_details.first_name || user_details.last_name
          ? `${user_details.first_name} ${user_details.last_name}`
          : 'User has no Name',
      url: '/app/profile',
    },
    {
      title: 'Log out',
      icon: 'exit',
      handleClick: () => dispatch(logoutAction()),
    },
  ];

  const dropdownItems = isTraidifyUser ? items : notTraidifyUserItems;

  const toggleTheme = () => {
    dispatch(toggleThemeAction());
  };

  // Close dropdown handler
  const handleClose = () => {
    setVisible(false);
  };

  // Generate menu items with styles from the original class
  const renderMenuItems = () => {
    return (
      <div className={styles.menu} style={{ cursor: 'pointer' }}>
        {dropdownItems.map((x, index) =>
          x.url ? (
            <Link
              style={{
                position: 'relative',
                display: 'flex',
                minHeight: '65px',
                color: theme === THEME_DARK_MODE ? 'white' : '#23262F',
                transition: 'color .2s',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom:
                  index < dropdownItems.length - 1
                    ? `1px solid ${theme === THEME_DARK_MODE ? '#333' : '#f0f0f0'}`
                    : 'none',
              }}
              to={x.url}
              onClick={handleClose}
              key={index}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexShrink: 0,
                  width: '20px',
                  height: '20px',
                  marginRight: '8px',
                }}
              >
                <Icon name={x.icon} size="20" />
              </div>
              <div
                style={{
                  flexGrow: 1,
                  paddingTop: '2px',
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    fontSize: '1em',
                    fontWeight: 'bold',
                  }}
                >
                  {x.title}
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    marginTop: '2px',
                    fontSize: '0.75em',
                    fontWeight: 500,
                    color: '#707070',
                  }}
                >
                  {x.content}
                </Typography>
              </div>
            </Link>
          ) : (
            <div
              style={{
                position: 'relative',
                display: 'flex',
                minHeight: '65px',
                color: theme === THEME_DARK_MODE ? 'white' : '#23262F',
                transition: 'color .2s',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom:
                  index < dropdownItems.length - 1
                    ? `1px solid ${theme === THEME_DARK_MODE ? '#333' : '#f0f0f0'}`
                    : 'none',
                cursor: 'pointer',
              }}
              key={index}
              onClick={() => {
                if (x.handleClick) x.handleClick();
                if (x.title !== 'Dark mode') handleClose();
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexShrink: 0,
                  width: '20px',
                  height: '20px',
                  marginRight: '8px',
                }}
              >
                <Icon name={x.icon} size="20" />
              </div>
              <div
                style={{
                  flexGrow: 1,
                  paddingTop: '2px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '-4px',
                  }}
                >
                  <div
                    style={{
                      fontSize: '1em',
                      fontWeight: 'bold',
                    }}
                  >
                    {x.title}
                  </div>
                  {x.title === 'Dark mode' ? (
                    <Switch
                      checked={theme === THEME_DARK_MODE}
                      onChange={toggleTheme}
                      name="theme-switch"
                    />
                  ) : (
                    ''
                  )}
                </div>
                <Typography
                  variant="caption"
                  style={{
                    marginTop: '2px',
                    fontSize: '0.75em',
                    fontWeight: 500,
                    color: '#707070',
                  }}
                >
                  {x.content}
                </Typography>
              </div>
            </div>
          )
        )}
      </div>
    );
  };

  return (
    <div className={cn(className, styles.user)}>
      <button ref={buttonRef} className={styles.head} onClick={() => setVisible(!visible)}>
        <UserProfileSVG width="40" height="40" />
      </button>

      {/* Use the portal component for the dropdown */}
      <DropdownPortal position={position} isVisible={visible} onClose={handleClose} theme={theme}>
        {renderMenuItems()}
      </DropdownPortal>
    </div>
  );
};

export default User;
